/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    background: #fffaef;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

a:active {
    background-color: transparent;
}

.drawer-menu-link,
.mat-drawer-container {
    color: #4a4a4a;
}

.mat-drawer-container {
    background-color: #fffaef;
}

.ka-web-container {
    background-color: #fffaef;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
}

.ka-web-container a,
.ka-web-container a:visited,
.ka-web-container a:active {
    color: #fed170;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

/* church details */
.ka-nolink {
    text-decoration: none;
    color: #000000;
    text-align: left;
}

.ka-link {
    color: #fed171;
}

.ka-select:active {
    background-color: rgba(254, 209, 113, 0.3);
}

.ka-church-item {
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ka-church-item img {
    width: 50px;
    height: 50px;
}

.ka-church-toggle {
    width: 100%;
    padding-left: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    vertical-align: top;
    font-size: 1.2em;
    border: 0px;
    background-color: #fffaef;
}

.ka-church-toggle mat-icon {
    padding-left: 10px;
    width: 40px;
}

.ka-church-toggle span {
    display: inline-block;
    padding-bottom: 10px;
    vertical-align: middle;
    color: #505050;
    font-weight: 500;
}

.ka-church-nodata {
    text-align: center;
    padding: 50px;
}

.ka-church-loading {
    padding: 50px;
}

.ka-separator {
    border-top: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.ka-underline {
    border-bottom: 2px solid #fed171;
}

.ka-tab-wrapper {
    height: calc(100% - 19px);
}

.map-container {
    width: 100% !important;
    height: 100% !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #000000
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fed171
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #fed171;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #fed171
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(254, 209, 113, 0.3);
}

.mat-option.mat-active {
    background: rgba(254, 209, 113, 0.3);
}

/* enable swype to work */
.mat-tab-body-wrapper {
    height: 100%;
}

@media only screen and (min-width: 600px) {
    body {
        font-size: 110%;
    }

    .mat-tab-label {
        font-size: 16px;
    }

    .ka-church-item img {
        width: 80px;
        height: 80px;
    }

    .ka-debug {
        background-color: rgb(211, 156, 54);
    }
}

.mat-card {
    background: #fffaef;
}

.ka-fav {
    border-bottom-color: #fed171 !important;
    border-bottom: 2px solid;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

h1 {
    font-size: 1.2em;
    color: #505050;
    font-weight: 500;
}

.ka-nav-text {
    font-size: 17px;
    vertical-align: super;
}

.mat-icon-rtl-mirror {
    color: #fed171;
}

.gm-style .gm-style-iw-d {
    padding: 5px;
}

@media only screen and (min-width: 600px) {

    .ka-nav-text {
        font-size: 20px;
        vertical-align: super;
    }
}